import { useCallback, useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer, S3ItemReference, UniversalService } from '@alliance-disposal/transport-types';
import { ImageDialog } from '@wayste/sour-components';
import { useSourContext } from '@wayste/sour-context';
import { Loading } from '@wayste/sour-ui';
import { ServiceSummary, formatServiceAddress, moneyFormatter, reduceServiceGroupingCurrent } from '@wayste/utils';
import { PencilIcon, PhotoIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/solid';
import { isAxiosError } from 'axios';
import { useHistory } from 'react-router-dom';
import ContactsListCard from '../../../components/ContactsListCard/ContactsListCard';
import CustomerDetailsCard from '../../../components/CustomerDetailsCard/CustomerDetailsCard';
import DetailsCardWrapper from '../../../components/DetailsCardWrapper';
import { InternalTicket } from '../../../components/InternalTicket';
import PayablesTableCard from '../../../components/PayablesTableCard/PayablesTableCard';
import ReceivableTableCard from '../../../components/ReceivableTableCard/ReceivableTableCard';
import { UIContext } from '../../../contexts';
import { routes } from '../../../utils';
import { repList } from '../../../utils/shared-types';
import UniversalServicesCancelDialog from '../components/UniversalServicesCancelDialog/UniversalServicesCancelDialog';
import UpdateServiceGroupingModal from '../components/service-grouping-edit-model';
import ServiceOrdersListTable from './ServiceOrdersListTable';
import SubscriptionDetailsCard from './SubscriptionDetailsCard';

interface ServiceGroupingMetrics {
    totalValue: number;
    totalCost: number;
    takeRatePercent: number;
    takeRateValue: number;
}

const calculateServiceGroupingMetrics = (
    serviceOrders: UniversalService.ServiceOrder[],
    vendorCosts: Map<string, number>,
): ServiceGroupingMetrics => {
    const latestOrders = latestServiceOrdersOnSite(serviceOrders);

    const totalValue = latestOrders.reduce(
        (acc, order) =>
            acc +
            order.events
                .filter((event) => event.eventType === 'synthetic' || event.eventType === 'subscription-recurring')
                .reduce((e, event) => e + event.unitPrice * event.priceQuantity, 0),
        0,
    );

    const totalCost = latestOrders.reduce((acc, order) => acc + (vendorCosts.get(order.serviceType.id) ?? 0), 0);

    const takeRatePercent = totalValue && totalCost ? ((totalValue - totalCost) / totalValue) * 100 : 0;
    const takeRateValue = totalValue - totalCost;

    return {
        totalValue,
        totalCost,
        takeRatePercent,
        takeRateValue,
    };
};

const ServiceGroupingDetails = ({ serviceGroupingId }: { serviceGroupingId: string }) => {
    const client = useWaysteClient();
    const history = useHistory();
    const { godModeActive } = useContext(UIContext);
    const { useConfirmationDialog, setShowToast } = useSourContext();
    const { getConfirmation } = useConfirmationDialog();
    const [cancelOpen, setCancelOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [serviceGrouping, setServiceGrouping] = useState<UniversalService.ServiceGrouping | undefined>();
    const [customer, setCustomer] = useState<Customer.AllianceCustomerTransport | undefined>(undefined);
    const [lastUpdatedBy, setLastUpdatedBy] = useState<string>('');
    const [createdBy, setCreatedBy] = useState<string>('');
    const [salesRep, setSalesRep] = useState<string>('');
    const [serviceGroupingSummary, setServiceGroupingSummary] = useState<ServiceSummary[]>([]);
    const [updateServiceGroupingModalOpen, setUpdateServiceGroupingModalOpen] = useState<boolean>(false);
    const [showImages, setShowImages] = useState<boolean>(false);
    const [files, setFiles] = useState<S3ItemReference[]>([]);
    const [dodgyLocalCachingForVendorCost, setDodgyLocalCachingForVendorCost] = useState<Map<string, number>>(new Map());
    const [metrics, setMetrics] = useState<ServiceGroupingMetrics>({
        totalValue: 0,
        totalCost: 0,
        takeRatePercent: 0,
        takeRateValue: 0,
    });

    const calculateVendorCosts = (serviceOrders: UniversalService.ServiceOrder[]) => {
        const newMap = new Map<string, number>();
        serviceOrders.forEach((serviceOrder) => {
            const vendorCost = serviceOrder.events
                .filter((event) => event.eventType === 'synthetic' || event.eventType === 'subscription-recurring')
                .reduce((_e, event) => event.unitCost * event.costQuantity, 0);
            if (vendorCost !== 0) {
                newMap.set(serviceOrder.serviceType.id ?? '', vendorCost);
            }
        });
        setDodgyLocalCachingForVendorCost(newMap);
        setMetrics(calculateServiceGroupingMetrics(serviceOrders, newMap));
    };

    const fetchCustomer = useCallback(
        async (customerId: string) => {
            try {
                const customerResponse = await client.customer().adminPortal.fetch(customerId);
                setCustomer(customerResponse);
            } catch (error) {
                console.warn(error);
                setShowToast({
                    severity: 'error',
                    message: 'Error fetching customer',
                });
            }
        },
        [client, setShowToast],
    );

    const refreshServiceGrouping = useCallback(async () => {
        setIsLoading(true);
        try {
            const serviceGroupingResponse = await client.universalService().serviceGrouping.fetch(serviceGroupingId);

            await fetchCustomer(serviceGroupingResponse.customerID);

            calculateVendorCosts(serviceGroupingResponse.serviceOrders);

            setServiceGrouping(serviceGroupingResponse);
            setServiceGroupingSummary(reduceServiceGroupingCurrent(serviceGroupingResponse));

            const lastUpdatedByUser = repList.find((user) => user.id === serviceGroupingResponse?.metadata.lastUpdatedByUserID);
            const createdByUser = repList.find((user) => user.id === serviceGroupingResponse?.metadata.createdByUserID);
            const salesRepUser = repList.find((user) => user.id === serviceGroupingResponse?.contractDetails?.salesRepID);
            if (lastUpdatedByUser !== undefined) {
                setLastUpdatedBy(lastUpdatedByUser?.firstName + ' ' + lastUpdatedByUser?.lastName);
            }
            if (createdByUser !== undefined) {
                setCreatedBy(createdByUser?.firstName + ' ' + createdByUser?.lastName);
            }
            if (salesRepUser !== undefined) {
                setSalesRep(salesRepUser?.firstName + ' ' + salesRepUser?.lastName);
            }
            setFiles(serviceGroupingResponse.images || []);
        } catch (err) {
            console.warn(err);
            setShowToast({
                severity: 'error',
                message: 'Error fetching service order',
            });
        } finally {
            setIsLoading(false);
        }
    }, [serviceGroupingId, client, fetchCustomer, setShowToast]);

    useEffect(() => {
        if (!serviceGroupingId) return;
        
        const init = async () => {
            setIsLoading(true);
            try {
                const serviceGroupingResponse = await client.universalService().serviceGrouping.fetch(serviceGroupingId);

                await fetchCustomer(serviceGroupingResponse.customerID);

                calculateVendorCosts(serviceGroupingResponse.serviceOrders);

                setServiceGrouping(serviceGroupingResponse);
                setServiceGroupingSummary(reduceServiceGroupingCurrent(serviceGroupingResponse));

                const lastUpdatedByUser = repList.find((user) => user.id === serviceGroupingResponse?.metadata.lastUpdatedByUserID);
                const createdByUser = repList.find((user) => user.id === serviceGroupingResponse?.metadata.createdByUserID);
                const salesRepUser = repList.find((user) => user.id === serviceGroupingResponse?.contractDetails?.salesRepID);
                if (lastUpdatedByUser !== undefined) {
                    setLastUpdatedBy(lastUpdatedByUser?.firstName + ' ' + lastUpdatedByUser?.lastName);
                }
                if (createdByUser !== undefined) {
                    setCreatedBy(createdByUser?.firstName + ' ' + createdByUser?.lastName);
                }
                if (salesRepUser !== undefined) {
                    setSalesRep(salesRepUser?.firstName + ' ' + salesRepUser?.lastName);
                }
                setFiles(serviceGroupingResponse.images || []);
            } catch (err) {
                console.warn(err);
                setShowToast({
                    severity: 'error',
                    message: 'Error fetching service order',
                });
            } finally {
                setIsLoading(false);
            }
        };

        init().catch(console.error);
    }, [serviceGroupingId, client, fetchCustomer, setShowToast]);

    const handleAppAdminDelete = async () => {
        if (!godModeActive) return;

        // first get confirmation from user
        const confirmed = await getConfirmation({
            message:
                'Are you sure you want to delete this service grouping? This action cannot be undone. In most cases, you should cancel the service grouping instead. This is only intended for use in testing or by admins.',
            title: 'Confirm Delete',
            cancelText: 'Cancel',
            confirmText: 'Delete',
        });

        if (!confirmed) return;

        // delete service grouping
        try {
            await client.universalService().serviceGrouping.delete(serviceGroupingId);
            setShowToast({
                severity: 'success',
                message: 'Service grouping deleted',
            });
            history.push(routes.universalServices.list);
        } catch (error) {
            if (isAxiosError(error) && error.response?.data.message) {
                setShowToast({
                    severity: 'error',
                    message: error.response.data.message,
                });
                return;
            }
            setShowToast({
                severity: 'error',
                message: 'Error deleting service grouping',
            });
        }
    };

    // CANCEL ENTIRE SERVICE GROUPING
    const onCancel = async () => {
        const cancelServiceGrouping: UniversalService.ServiceGroupingUpdate = {
            ...serviceGrouping,
            status: 'closed',
        };
        try {
            await client
                .universalService()
                .serviceGrouping.update(serviceGroupingId, cancelServiceGrouping)
                .then(() => {
                    setShowToast({
                        severity: 'success',
                        message: 'Canceling entire service grouping',
                    });
                });
        } catch (error) {
            setShowToast({
                severity: 'error',
                message: 'Error canceling service grouping',
            });
        }
    };

    const handleAddService = () => {
        if (serviceGrouping && serviceGrouping.type === 'single') {
            setShowToast({
                severity: 'warning',
                message: 'Add service to single service grouping not implemented',
            });
            return;
        }
        history.push(routes.universalServices.serviceGrouping.addOrder(serviceGroupingId));
    };

    const handleImagesChange = async (imageReferences: S3ItemReference[]) => {
        try {
            const response = await client.universalService().serviceGrouping.update(serviceGroupingId, { images: imageReferences });
            setFiles(response.images || []);
        } catch (error) {
            console.warn('Error changing service status: ', error);
            setShowToast({
                severity: 'error',
                message: 'Error occurred saving the image. Please try again.',
            });
        }
    };

    if (isLoading) return <Loading />;
    if (!serviceGrouping) return <div>Failed to fetch Service Grouping</div>;

    return (
        <div className="space-y-2">
            <UniversalServicesCancelDialog
                variant="delete"
                type={serviceGrouping?.type === 'single' ? 'oneTime' : 'subscription'}
                cancelOpen={cancelOpen}
                setCancelOpen={setCancelOpen}
                onCancel={onCancel}
            />
            {serviceGrouping && (
                <UpdateServiceGroupingModal
                    serviceGrouping={serviceGrouping}
                    onClose={() => {
                        setUpdateServiceGroupingModalOpen(false);
                        refreshServiceGrouping();
                    }}
                    open={updateServiceGroupingModalOpen}
                />
            )}
            <DetailsCardWrapper
                heading={(serviceGrouping?.type === 'single' ? 'Service Grouping ' : 'Subscription ') + serviceGrouping?.orderNumber}
                buttons={[
                    {
                        label: (
                            <>
                                <PencilIcon className="mr-2 size-4" /> Edit
                            </>
                        ),
                        onClick: () => setUpdateServiceGroupingModalOpen(true),
                        hide: serviceGrouping?.status === 'closed',
                    },
                    {
                        label: (
                            <>
                                <PhotoIcon className="mr-1 size-5" /> Service Image
                            </>
                        ),
                        onClick: () => setShowImages(true),
                        tooltip: "Are you sure you don't want to use Service Order images instead?",
                    },
                    {
                        label: (
                            <>
                                <TrashIcon className="mr-1 size-5" /> Delete
                            </>
                        ),
                        delete: true,
                        onClick: () => handleAppAdminDelete(),
                        hide: !godModeActive,
                    },
                ]}
            />
            {serviceGrouping?.type !== 'single' && serviceGrouping?.serviceOrders[0].subscriptionDetails && (
                <SubscriptionDetailsCard
                    subscriptionDetails={serviceGrouping?.serviceOrders[0].subscriptionDetails}
                    contractDetails={serviceGrouping?.contractDetails}
                    serviceGrouping={serviceGrouping}
                    anyServiceOrderNeedsAttention={serviceGrouping?.serviceOrders.some((order) => order.needsAttention) || false}
                    serviceAddress={
                        serviceGrouping?.serviceOrders[0].serviceLocation?.address &&
                        serviceGrouping?.serviceOrders.every(
                            (order) =>
                                formatServiceAddress(order.serviceLocation?.address) ===
                                formatServiceAddress(serviceGrouping?.serviceOrders[0].serviceLocation?.address),
                        )
                            ? formatServiceAddress(serviceGrouping?.serviceOrders[0].serviceLocation?.address)
                            : 'Multiple Addresses'
                    }
                    startDate={
                        serviceGrouping?.serviceOrders.every((order) => order.startDate === serviceGrouping?.serviceOrders[0].startDate)
                            ? serviceGrouping?.serviceOrders[0].startDate
                            : 'Multiple Start Dates'
                    }
                    endDate={
                        serviceGrouping?.serviceOrders.every((order) => order.endDate === serviceGrouping?.serviceOrders[0].endDate)
                            ? serviceGrouping?.serviceOrders[0].endDate
                            : 'Multiple Start Dates'
                    }
                    vendors={Array.from(new Set(serviceGrouping?.serviceOrders.map((order) => order.vendorName))).join(', ') ?? []}
                    lastUpdatedBy={lastUpdatedBy}
                    createdBy={createdBy}
                    salesRep={salesRep}
                />
            )}
            <DetailsCardWrapper
                heading="Summary (Beta)"
                buttons={
                    serviceGrouping?.status !== 'closed'
                        ? [
                              {
                                  label: (
                                      <>
                                          <PlusIcon className="mr-1 size-5" /> Add Service
                                          {serviceGrouping?.type !== 'single' && ' to Subscription'}
                                      </>
                                  ),
                                  onClick: handleAddService,
                              },
                          ]
                        : []
                }
            >
                <p className="text-sm font-semibold">
                    Please note that this summary may not be accurate. Please double check the service orders.
                </p>
                <div className="grid grid-cols-5 border-b p-1 text-right">
                    <p className="col-span-2 text-left text-sm font-semibold">Service</p>
                    <p className="text-sm">Quantity</p>
                    <p className="text-sm">Frequency</p>
                    <p className="text-sm">Price</p>
                </div>
                {serviceGroupingSummary.map((summary, index) => {
                    return (
                        <div className={`grid grid-cols-5 p-1 text-right ${index % 2 === 0 ? '' : 'bg-slate-100'}`} key={summary.sku.id}>
                            <p className="col-span-2 text-left text-sm font-semibold">
                                {summary.sku.family.name + ' - ' + summary.sku.name}
                            </p>
                            <p className="text-sm">{summary.quantity}</p>
                            <p className="text-sm font-semibold">
                                {summary.serviceFrequency.length === 1 ? (
                                    <p>{summary.serviceFrequency[0].frequency + ' per ' + summary.serviceFrequency[0].unit}</p>
                                ) : (
                                    summary.serviceFrequency
                                        .map((frequency) => {
                                            return frequency.quantity + 'x serviced ' + frequency.frequency + ' per ' + frequency.unit;
                                        })
                                        .join(', ')
                                )}
                            </p>
                            <p className="text-sm">{moneyFormatter(summary.price)} per cycle</p>
                        </div>
                    );
                })}
            </DetailsCardWrapper>
            <DetailsCardWrapper
                heading="Current Service Orders"
                chips={[
                    {
                        label: `${moneyFormatter(metrics.totalValue)} Total Value`,

                        textColor: 'text-black',
                        outlined: true,
                    },
                    {
                        label: `${moneyFormatter(metrics.totalCost)} Total Cost`,

                        textColor: 'text-black',
                        outlined: true,
                    },
                    {
                        label: `Take Rate ${metrics.takeRatePercent.toFixed(1)}% - ${moneyFormatter(metrics.takeRateValue)}`,

                        textColor: 'text-black',
                        outlined: true,
                    },
                ]}
                buttons={
                    serviceGrouping?.status !== 'closed'
                        ? [
                              {
                                  label: (
                                      <>
                                          <PlusIcon className="mr-1 size-5" />
                                          Add Service{serviceGrouping?.type !== 'single' && ' to Subscription'}
                                      </>
                                  ),
                                  onClick: handleAddService,
                              },
                          ]
                        : []
                }
            >
                <ServiceOrdersListTable
                    serviceGrouping={serviceGrouping}
                    serviceOrders={serviceGrouping?.serviceOrders.filter((order) => order.status !== 'COMPLETED') || []}
                    dodgyLocalCachingForVendorCost={dodgyLocalCachingForVendorCost}
                    onRowClicked={(row) => {
                        if (!serviceGrouping?.id) return;
                        history.push(routes.universalServices.serviceGrouping.serviceOrder.details(serviceGrouping.id, row.id));
                    }}
                    deletable
                />
            </DetailsCardWrapper>
            <div className="mb-3">
                <InternalTicket entityID={serviceGrouping?.id} entityType="sourgum-service-grouping" />
            </div>

            <ReceivableTableCard
                suppressQuery={!serviceGrouping?.invoiceGroupings.length}
                serviceGroupings={serviceGrouping ? [serviceGrouping] : []}
                receivableQuery={{
                    productInvoiceGroupingID: serviceGrouping?.invoiceGroupings.map((invoiceGrouping) => invoiceGrouping.id).join(','),
                }}
            />
            <PayablesTableCard
                suppressQuery={!serviceGrouping?.serviceOrders.length}
                serviceOrderIds={serviceGrouping?.serviceOrders.map((serviceOrder) => serviceOrder.id) ?? []}
                serviceGrouping={serviceGrouping}
            />
            {customer && <CustomerDetailsCard customer={customer} />}
            {customer && <ContactsListCard customer={customer} />}
            <DetailsCardWrapper heading="Past Service Orders">
                <ServiceOrdersListTable
                    deletable={false}
                    serviceOrders={serviceGrouping?.serviceOrders?.filter((order) => order.status === 'COMPLETED') ?? []}
                    dodgyLocalCachingForVendorCost={dodgyLocalCachingForVendorCost}
                    onRowClicked={(row) => {
                        if (!serviceGrouping?.id) return;
                        history.push(routes.universalServices.serviceGrouping.serviceOrder.details(serviceGrouping.id, row.id));
                    }}
                />
            </DetailsCardWrapper>
            <ImageDialog
                open={showImages}
                onClose={() => setShowImages(false)}
                entityID={serviceGrouping.id}
                imageReferences={files}
                setImageReferences={handleImagesChange}
            />
        </div>
    );
};

export default ServiceGroupingDetails;
function latestServiceOrdersOnSite(
    serviceOrders: UniversalService.ServiceOrder[],
) {
    const onSiteOrders = serviceOrders.filter((order) => order.status === 'ON_SITE');

    // Find latest date range
    const latestStartDate = Math.max(
        ...onSiteOrders.filter((order) => order.startDate).map((order) => new Date(order.startDate!).getTime()),
    );
    const latestEndDate = Math.max(
        ...onSiteOrders
            .filter((order) => order.endDate)
            .map((order) => order.endDate ? new Date(order.endDate).getTime() : 0),
    );

    // Filter to only orders with latest date range
    return onSiteOrders.filter((order) => {
        if (!order.startDate) return false;
        const orderStart = new Date(order.startDate).getTime();
        if (!order.endDate) {
            return orderStart === latestStartDate;
        }
        const orderEnd = new Date(order.endDate).getTime();
        return orderStart === latestStartDate && orderEnd === latestEndDate;

    });
}
